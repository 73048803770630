import { kratos } from "@digitalsurance/auth";
import type { TenantConfig } from "src/hooks/useGlobalConfig";

export const translateError = (
  error: kratos.UiText,
  tenantConfig: TenantConfig
) => {
  switch (error.id) {
    // Password too short
    case 4000005:
      error.text =
        "A jelszó túl rövid, legalább 8 karakter hosszúnak kell lennie.";
      break;

    // Please confirm this action by verifying that it is you.
    case 1010003:
      error.text = "Kérjük erősítse meg ezt a műveletet";
      break;

    // The provided credentials are invalid, check for spelling mistakes in your password or username, email address, or phone number.
    case 4000006:
      error.text = "Hibás felhasználónév vagy jelszó";
      break;
    // An email containing a recovery link has been sent to the email address you provided.
    case 1060002:
      error.text =
        "A visszaállításhoz szükséges linket elküldtük az email címére.";
      break;
    case 1337:
      error.text = `${tenantConfig.name} alkalmazottaknak ez a funkció nem engedélyezett.`;
    default:
      break;
  }
  return error;
};
